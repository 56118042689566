
import Button from '../../views/button/button';
import { Col, Container, Row } from "react-bootstrap";
import Text from '../../views/text/text';
import './banner.css';
import './banner-sm.css';
import './banner-md.css';
import VideoBackground from './images/home_banner.png';
import Player from "../../views/player/player";
import { useEffect, useState } from 'react';

function Banner() {
    const [width, setWidth]   = useState(window.innerWidth);
    const updateDimensions = () => {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    return (
        <Container id="home" fluid={width > 992}>
            <Row className="justify-content-md-end align-items-center">
                <Col lg="4" xs="12" className="captions">
                    <section className="captionsTitle">Time is <span><b>Valuable</b></span></section>
                    <section><Text thin>With OnTime ITS you can run your healthcare company with ease, saving you time to use it where it really matters.</Text></section>
                    <section><Button large className="demoButton" href="/demo">Get a Demo</Button>
                        <span className='small-device-video-player'><Player type="button" video="5IkKfZpYIQU"></Player></span>
                    </section>
                    <section className="captionsPara"><Text light>Our web and mobile platforms will solve your problems and keep your company running smoothly. We do so by automating most of the processes, So that your payroll and billing can be done in seconds with 100% accuracy! </Text></section>
                </Col>
                <Col className="bannerImage d-flex flex-wrap align-items-center px-5" xl="6" lg="7">
                    <section className="video-btn-parent">
                        <img src={VideoBackground} alt="video background"></img>
                        <Player type="icon" video="5IkKfZpYIQU"></Player>
                    </section>
                    {/* <img src={pcImage} alt="ontime app banner"></img> */}
                </Col>


            </Row>
            <Row className="invest-row align-items-center mt-5 mt-lg-0">
                <section className="d-md-flex invest-row-section"><Text thin className="investors-desc">Our crowdfunding campaign is now live and accepting investments!</Text> <Button href="https://www.startengine.com/offering/aaidebook" className="invest-button">Invest Now</Button></section>
            </Row>
        </Container>
    );
}

export default Banner;